import {
  CardContent,
  Chip,
  FormControl,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckBoxMenu from "../controls/CheckBoxMenu";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import Dropdown from "../../components/controls/Dropdown";
import AutoComplete from "../../components/controls/AutoComplete";
import Grid from "@material-ui/core/Grid";
import "../../styles/style.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleBackdrop from "../controls/Spinner";
import {
  API,
  ASYNC_STORAGE,
  CALENDAR_STATUS,
  CASE_STATUS,
  ROLE_CASES,
} from "../../constants";
import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../components/controls/Button";
import DatePickers from "../../components/controls/DatePicker";
import { isUserHasMPSrole } from "../../utils/calendar";
import {
  setRHSStatus,
  setRHSCanel,
  checkUserIntrnlCommentsAccess,
  checkForInHousePersona,
  displayStatusLabel,
} from "../../utils/tpr";

import moment from "moment";
import MultiSelectStatus from "../controls/MultiSelectStatus";
import { setAppliedFilters } from "../../redux/modules/surgicalCases";
import SaveFilterDialog from "./SaveFilterDialog";
import { StyledCheckbox } from "../controls/StyledCheckbox";
import { deletePresetFilter, savePresetFilters, createOrUpdateTPR, trackTPRDownload } from "../../graphql/mutations";
import PresetFilterMenu from "./PresetFilterMenu";
import { ArrowDropDown } from "@material-ui/icons";
import Snackbars from "../controls/Snackbar";
import axios from "axios";
import fileDownload from "js-file-download";



const Filter = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: "0px",
    },
    filterText: {
      marginTop: "4px",
      paddingLeft: "11px",
    },
    filterIcon: {
      marginTop: "4px",
    },
    createButton1: {
      background: "#FFB500",
      boxShadow: "none",
      color: "#000",
      fontWeight: "bold",
      fontSize: "12px",
      fontFamily: "Helvetica",

      "&:hover": {
        background: "#FFD271",
        boxShadow: "none",
        color: "#000",
      },
      "&:focus": {
        background: "#FFB500!important",
        boxShadow: "none",
        color: "#000",
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
    },
    checkboxall: {
      margin: "25px 0px 0px 10px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 500,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    presetFilterButton: {
      borderBottom: "0.5px solid lightgray",
      background: "white",
    },
    presetFilterText: {
      width: "inherit",
      textAlign: "left",
      textTransform: "none",
      fontSize: "16px",
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const caseTypes = ["Surgery Date Confirmed ?", "MAKO", "AS-1", "Error"];
  const current_user = JSON.parse(
    localStorage.getItem(ASYNC_STORAGE.CURRENT_USER) || "{}"
  );
  const { divisions, branchIds, sfids } = current_user;
  const [loader, setLoader] = React.useState(false);
  const [downloadComplete, setDownloadComplete] = React.useState(false);
  const setFilterObj = (filters) => dispatch(setAppliedFilters(filters));
  let filterObj = useSelector((state) => state.surgicalCases.appliedFilters);
  filterObj =
    filterObj && Object.keys(filterObj).length > 0
      ? filterObj
      : props.initialFilter;

  const [divisionOptions, setDivisionOption] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [salesTeamOptions, setSalesTeamOptions] = useState([]);
  const [salesRepOptions, setSalesRepOptions] = useState({});
  const [hospitalOptions, setHospitalOptions] = useState({});
  const [surgeonOptions, setSurgeonOptions] = useState({});
  const [open, setOpen] = useState(false);
  const [saveFilter, setSaveFilter] = useState(false);
  const [presetFilters, setPresetFilters] = useState([]);
  const [anchor, setAnchor] = useState(null);
  const [notification, setNotification] = useState({
    severity: "",
    message: "",
  });
  const [openSnakBar, setOpenSnakBar] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    localStorage.setItem("selectedCheckBoxes", null);
  }, ["check"]);

  useEffect(() => {
    // Division Dropdown Options
    let divisionOptions = [];
    if (divisions && divisions.length > 0) {
      divisionOptions = divisions.map((division) => ({
        id: division,
        label: division !== "Trauma" ? division : "Trauma & Extremities",
      }));
    }
    setDivisionOption(divisionOptions);

    // BranchDropdown Options
    const compareBranch = (a, b) => {
      const c1 = a.label;
      const c2 = b.label;
      var comp = 0;
      if (c1 > c2) comp = 1;
      else if (c1 < c2) comp = -1;
      return comp;
    };
    var a = branchIds.toString();
    var c = a.split(",");
    props.getMainBranches()
      .then((res) => {
        const sortedUserByBranch = res.get_main_branches.sort(compare);
        let y = sortedUserByBranch.map((item) => {
          return { label: item.branchName, value: item.branchID };
        });
        return y;
      })
      .then((res) => {
        setBranchOptions(res);
      });
    // Sales Rep
    const compare = (a, b) => {
      const c1 = a.userName;
      const c2 = b.userName;
      var comp = 0;
      if (c1 > c2) comp = 1;
      else if (c1 < c2) comp = -1;
      return comp;
    };
    props.getBranchSalesrep(branchIds)
      .then((res) => {
        const sortedUserByBranch = res.get_user_by_branch.sort(compare);
        let y = sortedUserByBranch.map((item) => {
          item.label = item.userName;
          item.value = item.userID;
          return item;
        });
        return y;
      })
      .then((res) => {
        setSalesRepOptions(res);
      });

    // Sales Team Dropdown Options
    props.getSalesTeam(sfids[0])
      .then((res) => {
        return res.filter_sales_team.map((item) => {
          return { label: item.label, value: item.id };
        });
      })
      .then((res) => {
        setSalesTeamOptions(res);
      });
    // Hospital
    const compareHospital = (a, b) => {
      const c1 = a.hospitalName.split(" - ");
      const c2 = b.hospitalName.split(" - ");
      var comp = 0;
      if (c1[1] > c2[1]) comp = 1;
      else if (c1[1] < c2[1]) comp = -1;
      return comp;
    };
    props.getHospitals()
      .then((res) => {
        const sortedByHospitalName =
          res.data.get_hospitals.sort(compareHospital);
        return sortedByHospitalName.map((item) => {
          return { label: item.hospitalName, value: item.hospitalId };
        });
      })
      .then((res) => {
        setHospitalOptions(res);
      });

    // Surgeon
    const compareSurgeon = (a, b) => {
      const c1 = a.surgeonName.split(" - ");
      const c2 = b.surgeonName.split(" - ");
      var comp = 0;
      if (c1[1] > c2[1]) comp = 1;
      else if (c1[1] < c2[1]) comp = -1;
      return comp;
    };
    //Role Surgeon
    props.getSurgeons()
      .then((res) => {
        const sortedBySurgeonName =
          res.get_surgeons && res.get_surgeons.sort(compareSurgeon);
        return sortedBySurgeonName.map((item) => {
          return { label: item.surgeonName, value: item.surgeonID };
        });
      })
      .then((res) => {
        setSurgeonOptions(res);
      });

    //Role Options
    const options = [
      {
        id: ROLE_CASES.MY_CASES,
        label: ROLE_CASES.MY_CASES,
        value: 1,
      },
      {
        id: ROLE_CASES.COVERING_CASES,
        label: ROLE_CASES.COVERING_CASES,
        value: 2,
      },
      {
        id: ROLE_CASES.TEAM_CASES,
        label: ROLE_CASES.TEAM_CASES,
        value: 3,
      },
      {
        id: ROLE_CASES.BRANCH_CASES,
        label: ROLE_CASES.BRANCH_CASES,
        value: 4,
      },
    ];

    setRoleOptions(options);

    // Get Saved Filters
    getFilters(sfids[0]);
  }, []);

  useEffect(() => {
    // While Navigating in Calendar View
    if (props.currentDate && props.removeDateRange) {
      let obj = { ...filterObj, startDate: "", endDate: "" };
      applyfilters(obj);
    }
  }, [props.currentDate, props.removeDateRange]);

  const compareFilterName = (a, b) => {
    const c1 = a.name.toUpperCase();
    const c2 = b.name.toUpperCase();
    var comp = 0;
    if (c1[0] > c2[0]) comp = 1;
    else if (c1[0] < c2[0]) comp = -1;
    return comp;
  };

  const getFilters = async (userSfid) => {
    props.getPresetFilters(userSfid)
      .then((res) => {
        const sortedFilters =
          res.data.filters && res.data.filters.sort(compareFilterName);
        return (
          sortedFilters &&
          sortedFilters.map((item) => {
            item.label = item.name;
            item.id = item.id;
            return item;
          })
        );
      })
      .then((res) => setPresetFilters(res))
      .catch((error) =>
        displaySnackBarMessage(
          "error",
          "Something went wrong while fetching Preset Filters"
        )
      );
  };

  const handleDivisionDropdown = (event) => {
    let val = event.target.value;
    props.setDivision(val ? val : "All");
    let obj = { ...filterObj, division: val === "All" ? "" : val };
    setFilterObj(obj);
  };

  const handleBranchDropdown = (event) => {
    let arrBranch = [];
    if (
      filterObj.filterBranch !== undefined ||
      filterObj.filterBranch.length !== 0
    ) {
      arrBranch = event;
    }
    props.setBranch(event);
    let obj = {
      ...filterObj,
      filterBranch: arrBranch.map((item) => item.value).toString(),
    };
    setFilterObj(obj);
  };

  const handleSalesTeamDropdown = (value) => {
    let arrsalesTeam = [];
    if (
      filterObj.filterSalesTeam !== undefined ||
      filterObj.filterSalesTeam.length !== 0
    ) {
      arrsalesTeam = value;
    }
    props.setSalesTeam(arrsalesTeam);
    let obj = {
      ...filterObj,
      filterSalesTeam: arrsalesTeam.map((item) => item.value).toString(),
    };
    setFilterObj(obj);
  };

  const handleSalesRepDropdown = (event) => {
    let arrSalesRep = [];
    if (event.length >= 0) {
      if (
        filterObj.filterSalesRep !== undefined ||
        filterObj.filterSalesRep.length !== 0
      ) {
        arrSalesRep = event;
        props.setSalesRep(arrSalesRep);
        let obj = {
          ...filterObj,
          filterSalesRep: arrSalesRep
            ? arrSalesRep.map((item) => item.value).toString()
            : "",
        };
        setFilterObj(obj);
      }
    }
  };

  const handleStatusDropdown = (event) => {
    let val = event.target.value;
    props.setStatus(val ? val : "All");
    let obj = { ...filterObj, status: val === "All" ? "" : val };
    setFilterObj(obj);
  };

  const handleHospitalChange = (value) => {
    let arrHospital = [];
    if (
      filterObj.filterHospital !== undefined ||
      filterObj.filterHospital.length !== 0
    ) {
      arrHospital = value;
    }
    props.setHospital(arrHospital);
    let obj = {
      ...filterObj,
      filterHospital: arrHospital.map((item) => item.value).toString(),
    };
    setFilterObj(obj);
  };
  const handleSurgeonChange = (value) => {
    let arrSurgeon = [];
    if (
      filterObj.filterSurgeon !== undefined ||
      filterObj.filterSurgeon.length !== 0
    ) {
      arrSurgeon = value;
    }
    props.setSurgeon(arrSurgeon);
    let obj = {
      ...filterObj,
      filterSurgeon: arrSurgeon.map((item) => item.value).toString(),
    };
    setFilterObj(obj);
  };

  const onChangeFromDate = (event) => {
    let obj = { ...filterObj, startDate: event.target.value };
    props.setStartDate(event.target.value);
    if (event.target.value >= props.endDate) {
      props.setEndDate("");
      obj = { ...obj, endDate: null };
    }
    setFilterObj(obj);
  };

  const onChangeToDate = (event) => {
    props.setEndDate(event.target.value);
    let obj = { ...filterObj, endDate: event.target.value };
    setFilterObj(obj);
  };

  const handleCheckBoxClick = (event) => {
    let obj = { ...filterObj };
    if (event.target.name === "MAKO") {
      props.setMako(event.target.checked);
      obj = { ...filterObj, mako: event.target.checked };
    } else if (event.target.name === "AS-1") {
      props.setAs1(event.target.checked);
      obj = { ...filterObj, as1: event.target.checked };
    } else if (event.target.name === "Error") {
      props.setWebOpsError(event.target.checked);
      obj = { ...filterObj, isWebOpsError: event.target.checked };
    } else {
      props.setConfirmed(event.target.checked);
      obj = { ...filterObj, surgery_date_confirmed: event.target.checked };
    }
    setFilterObj(obj);
  };

  const handleFilterClick = () => {
    props.getCasesByFilter(filterObj);
    localStorage.setItem("filterObj", JSON.stringify(filterObj));
    if (props.checkFilterName !== null) {
      localStorage.setItem("checkFilterName", JSON.stringify(props.checkFilterName));
    }
    props.setOpen(false);
  };

  const handleresetClick = () => {
    localStorage.removeItem("filterObj");
    localStorage.removeItem("checkFilterName");
    localStorage.removeItem("SelectedFilter");
    applyfilters(props.initialFilter);
    props.setSelectedFilterName("");
    props.setCheckFilterName("");
    props.resetFilter();
  };

  const handleDelete = (name) => {
    const action = chipAction(true, name);
    const actionVal =
      name === "mako" ||
        name === "as1" ||
        name === "isWebOpsError" ||
        name === "surgery_date_confirmed"
        ? false
        : name === "status"
          ? CALENDAR_STATUS
          : "";
    action(actionVal);
    // Handle Date Range
    if (name === "startDate") {
      props.setEndDate("");
    }
    let obj = { ...filterObj, [name]: name === "status" ? "" : actionVal };
    setFilterObj(obj);

    if (!props.open) {
      props.getCasesByFilter(obj);
    }
  };

  // if Cliked on Delete, returns a action method which deletes the chip otherwise returns the chip to display
  const chipAction = (isDelete, key) => {
    switch (key) {
      case "mako":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"MAKO"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setMako
        );
      case "as1":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"AS-1"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setAs1
        );
      case "surgery_date_confirmed":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"Confirmed"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setConfirmed
        );
      case "isWebOpsError":
        return !isDelete && filterObj[key] ? (
          <Chip
            label={"Error"}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setWebOpsError
        );
      case "status":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getStatusLabel(filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setStatus
        );
      case "filterBranch":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setBranch
        );
      case "division":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={filterObj[key]}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setDivision
        );
      case "filterSalesRep":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setSalesRep
        );
      case "filterHospital":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setHospital
        );
      case "filterSalesTeam":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setSalesTeam
        );
      case "filterSurgeon":
        return !isDelete && filterObj[key] !== "" ? (
          <Chip
            label={getChipLabel(key, filterObj[key])}
            onDelete={() => handleDelete(key)}
            className={classes.chip}
          />
        ) : (
          props.setSurgeon
        );
      case "startDate":
        if (!isDelete && props.startDate) {
          let lbl = "Date Range Applied";
          let To =
            props.endDate && props.endDate !== ""
              ? `To ${moment(props.endDate).format("MM/DD/YYYY")}`
              : "";
          lbl = `${moment(props.startDate).format("MM/DD/YYYY")} ${To}`;
          return (
            <Chip
              label={lbl}
              onDelete={() => handleDelete(key)}
              className={classes.chip}
            />
          );
        } else {
          return props.setStartDate;
        }
      case "endDate":
        if (!isDelete && filterObj[key] !== "") {
          return null; // Chip rendered in Start Date as Form To
        } else {
          return props.setEndDate;
        }
      case "role":
        if (!isDelete && filterObj[key]) {
          return (
            <Chip
              label={filterObj[key]}
              onDelete={() => handleDelete(key)}
              className={classes.chip}
            />
          );
        } else {
          return props.setRole;
        }
      default:
        return null;
    }
  };

  const handleStatusSelection = (event, options) => {
    let values = [];
    if (event.target) {
      if (event.target.innerText === "All") {
        if (options.findIndex((item) => item.id === "All") > -1) {
          values = CALENDAR_STATUS;
        } else {
          values = [];
        }
      } else if (event.target.innerText) {
        const selctedStatus = options.filter((item) => item.id !== "All");
        values = selctedStatus.length === 7 ? CALENDAR_STATUS : selctedStatus;
      } else {
        if (
          props.status.find((item) => item.id === "All") &&
          !options.find((item) => item.id === "All")
        ) {
          values = [];
        } else if (
          !props.status.find((item) => item.id === "All") &&
          options.find((item) => item.id === "All")
        ) {
          values = CALENDAR_STATUS;
        } else {
          const selctedStatus = options.filter((item) => item.id !== "All");
          values = selctedStatus.length === 7 ? CALENDAR_STATUS : selctedStatus;
        }
      }

      let statusString = "";
      if (values.length > 0 && values.length < 7) {
        values.forEach((v) => {
          statusString += `${v.label},`;
        });
        statusString = statusString.slice(0, -1);
      }
      let obj = { ...filterObj, status: statusString };
      setFilterObj(obj);

      props.setStatus(values);
    }
  };

  const handleRoleSelect = (e) => {
    props.setRole(e.target.value);
    const obj = { ...filterObj, role: e.target.value };
    setFilterObj(obj);
  };

  const hadleSetControls = (appliedFilters) => {
    {
      Object.keys(appliedFilters).forEach((key) => {
        const action = chipAction(true, key);
        // Need to handle based on control
        if (action) {
          if (action === props.setStatus) {
            const statusArr = appliedFilters[key].split(",");
            props.setStatus(statusArr);
          } else if (action === props.setHospital) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < hospitalOptions.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === hospitalOptions[i].value) {
                  strArr.push(hospitalOptions[i]);
                }
              }
            }
            props.setHospital(strArr);
          } else if (action === props.setSurgeon) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < surgeonOptions.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === surgeonOptions[i].value) {
                  strArr.push(surgeonOptions[i]);
                }
              }
            }
            props.setSurgeon(strArr);
          } else if (action === props.setBranch) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < branchOptions.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === branchOptions[i].value) {
                  strArr.push(branchOptions[i]);
                }
              }
            }
            props.setBranch(strArr);
          } else if (action === props.setSalesRep) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < salesRepOptions.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === salesRepOptions[i].value) {
                  strArr.push(salesRepOptions[i]);
                }
              }
            }
            props.setSalesRep(strArr);
          } else if (action === props.setSalesTeam) {
            let strArr = [];
            const statusArr = appliedFilters[key].split(",");
            for (let i = 0; i < salesTeamOptions.length; i++) {
              for (let j = 0; j < statusArr.length; j++) {
                if (statusArr[j] === salesTeamOptions[i].value) {
                  strArr.push(salesTeamOptions[i]);
                }
              }
            }
            props.setSalesTeam(strArr);
          } else if (action === props.setStartDate || action === props.setEndDate) {
            // const dt = appliedFilters[key].includes('"') ? JSON.parse(appliedFilters[key]) : JSON.stringify(appliedFilters[key])
            action(
              appliedFilters[key]
                ? moment(appliedFilters[key]).format("YYYY-MM-DD")
                : ""
            );
          } else {
            action(appliedFilters[key]);
          }
        }
      });
    }
  };

  const applyfilters = (filters = props.initialFilter) => {
    hadleSetControls(filters);
    setFilterObj(filters);
  };
  // Preset Checkbox
  const handleCheckBox = async (filterId) => {
    props.setSelectedFilterName("");
    localStorage.removeItem("filterObj");
    localStorage.removeItem("checkFilterName");
    localStorage.removeItem("SelectedFilter");
    const selectedPreset = presetFilters.find((item) => item.id === filterId);
    props.setCheckFilterName(selectedPreset ? selectedPreset.name : "");

    if (selectedPreset) {
      const { __typename, ...filterDefinition } = selectedPreset.definition;
      const filterObject = getFilterObjFromDefinition(filterDefinition);
      applyfilters(filterObject);
      props.getCasesByFilter(filterObject);
      await savePresetFilters(
        selectedPreset.name,
        sfids[0],
        filterDefinition,
        selectedPreset.isdefault === false ? true : false
      );

      await getFilters(sfids[0]);
    }
    if (selectedPreset.isdefault === true) {
      applyfilters(props.initialFilter);
      props.getCasesByFilter(props.initialFilter);
      props.setCheckFilterName("");
      await getFilters(sfids[0]);
    }

    setAnchor(null);
  };
  const handlePresetFilterChange = (filterId) => {
    // setSelectedFilter(filterId)
    const selectedPreset = presetFilters.find((item) => item.id === filterId);
    localStorage.removeItem("checkFilterName");
    props.setSelectedFilterName(selectedPreset ? selectedPreset.name : "");
    if (selectedPreset) {
      // remove type
      const { __typename, ...filterDefinition } = selectedPreset.definition;
      const filterObject = getFilterObjFromDefinition(filterDefinition);
      localStorage.setItem("filterObj", JSON.stringify(filterObject));
      localStorage.setItem("SelectedFilter", JSON.stringify(selectedPreset.name));
      applyfilters(filterObject);
      props.getCasesByFilter(filterObject);
      // setOpen(false);
    }
    setAnchor(null);
  };

  const handleSaveFilter = async (filterName) => {
    const filterNameExists = presetFilters.find(
      (item) => item.name.toUpperCase() === filterName.toUpperCase()
    );
    if (filterNameExists) {
      displaySnackBarMessage("error", "Please Enter Different Filter Name.");
      return;
    }

    setSaveFilter(false);
    try {
      const { searchText, ...obj } = filterObj;
      //as per mobile
      const filterDefinition = buildFilterDefinition(obj);
      await savePresetFilters(filterName, sfids[0], filterDefinition);
      await getFilters(sfids[0]);
      props.setSelectedFilterName(filterName);
      displaySnackBarMessage("success", "Filter has been saved");
    } catch (error) {
      displaySnackBarMessage(
        "error",
        "Something went wrong while saving filter. Please try again."
      );
    }
  };

  const handleDeleteFilter = async (filterId) => {
    try {
      const selFilter = presetFilters.find((item) => item.id === filterId);
      await deletePresetFilter(filterId);
      await getFilters(sfids[0]);
      // if Deleted Selected Filter
      if (selFilter.name === props.selectedFilterName) {
        props.setSelectedFilterName("");
        applyfilters();
      }
      displaySnackBarMessage("success", "Filter has been deleted");
    } catch (error) {
      displaySnackBarMessage(
        "error",
        "Something went wrong while deleting filter. Please try again."
      );
    }
  };

  const handleUpdateFilter = async () => {
    setSaveFilter(false);
    try {
      const selectedPreset = presetFilters.find(
        (item) => item.name === props.selectedFilterName
      );
      const { searchText, ...obj } = filterObj;
      //as per mobile
      const filterDefinition = buildFilterDefinition(obj);
      await savePresetFilters(selectedPreset.name, sfids[0], filterDefinition);
      await getFilters(sfids[0]);
      displaySnackBarMessage("success", "Filter has been updated");
    } catch (error) {
      displaySnackBarMessage(
        "error",
        "Something went wrong while updating filter. Please try again."
      );
    }
  };

  const getFilterObjFromDefinition_WEB = (definition) => {
    let obj = {};
    Object.keys(definition).forEach((key) => {
      switch (key) {
        case "mako":
        case "as1":
        case "isWebOpsError":
        case "surgery_date_confirmed":
          const blnValue =
            definition[key] === true ||
            (definition[key] &&
              definition[key].toString().toLowerCase() === "true");
          obj = { ...obj, [key]: blnValue };
          break;
        default:
          obj = { ...obj, [key]: definition[key] };
          break;
      }
    });
    return obj;
  };

  // had to get obj as per mobile defintion :-(
  const getFilterObjFromDefinition = (definition) => {
    let obj = {};
    Object.keys(definition).forEach((key) => {
      switch (key) {
        case "startDate":
        case "endDate":
          let dt = null;
          if (definition[key]) {
            dt = definition[key].includes('"')
              ? JSON.parse(definition[key])
              : JSON.stringify(definition[key]);
          }
          obj = { ...obj, [key]: dt };
          break;
        case "mako":
        case "as1":
        case "isWebOpsError":
        case "surgery_date_confirmed":
          obj = {
            ...obj,
            [key]:
              definition[key] && JSON.parse(definition[key])
                ? JSON.parse(definition[key]).value
                : false,
          };
          break;
        case "role":
          obj = {
            ...obj,
            [key]:
              definition[key] && JSON.parse(definition[key])
                ? JSON.parse(definition[key]).label
                : null,
          };
          break;
        default:
          obj = {
            ...obj,
            [key]:
              definition[key] && JSON.parse(definition[key])
                ? JSON.parse(definition[key]).value
                : "",
          };
          break;
      }
    });
    return obj;
  };

  // mimc mobile way :-(
  const buildFilterDefinition = (filters) => {
    let obj = {};
    let val;
    let lbl;
    Object.keys(filters).forEach((key) => {
      switch (key) {
        case "startDate":
        case "endDate":
          obj = {
            ...obj,
            [key]: filters[key]
              ? `"${new Date(filters[key]).toISOString()}"`
              : null,
          };
          break;
        case "mako":
        case "as1":
        case "surgery_date_confirmed":
          lbl = key === "mako" ? "MAKO" : key === "as1" ? "AS-1" : "Confirmed";
          val = filters[key]
            ? `{"key":"${[key]}","label":"${lbl}","value":${filters[key]}}`
            : null;
          obj = { ...obj, [key]: val };
          break;
        case "isWebOpsError":
          val = filters[key]
            ? `{"label":"Error","value":${filters[key]}}`
            : null;
          obj = { ...obj, [key]: val };
          break;
        case "status":
          val = filters[key]
            ? `{"key":"${[key]}","label":"${getStatusLabel(
              filters[key]
            )}","value":"${filters[key]}"}`
            : null;
          obj = { ...obj, [key]: val };
          break;
        case "division":
          val = filters[key]
            ? `{"label":"${filters[key]}","value":"${filters[key]}"}`
            : null;
          obj = { ...obj, [key]: val };
          break;
        case "role":
          const roleVal =
            filters[key] === ROLE_CASES.MY_CASES
              ? 1
              : filters[key] === ROLE_CASES.COVERING_CASES
                ? 2
                : filters[key] === ROLE_CASES.TEAM_CASES
                  ? 3
                  : filters[key] === ROLE_CASES.BRANCH_CASES
                    ? 4
                    : null;
          val = filters[key]
            ? `{"label":"${filters[key]}","value":${roleVal}}`
            : null;
          obj = { ...obj, [key]: val };
          break;
        default:
          val = filters[key]
            ? `{"label":"${getChipLabel(key, filters[key])}","value":"${filters[key]
            }"}`
            : null;
          obj = { ...obj, [key]: val };
          break;
      }
    });
    return obj;
  };

  const getStatusLabel = (statusVal) => {
    const stsArr = statusVal !== "" && statusVal.split(",");
    return `${stsArr[0]} ${stsArr.length > 1 > 0 ? `(+${stsArr.length - 1})` : ""
      }`;
  };

  const getChipLabel = (key, value) => {
    const options =
      key === "filterBranch"
        ? filterObj.filterBranch
        : key === "filterSalesRep"
          ? filterObj.filterSalesRep
          : key === "filterSalesTeam"
            ? filterObj.filterSalesTeam
            : key === "filterHospital"
              ? filterObj.filterHospital
              : key === "filterSurgeon"
                ? filterObj.filterSurgeon
                : "";
    if (key === "filterSalesRep") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `salesRep( + ${str.length} ) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterSalesRep;
      return option;
    } else if (key === "filterSalesTeam") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Sales Team (+ ${str.length} ) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterSalesTeam;
      return option;
    } else if (key === "filterSurgeon") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Surgeon (+ ${str.length}) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterSurgeon;
      return option;
    } else if (key === "filterHospital") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Hospital (+ ${str.length}) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterHospital;
      return option;
    } else if (key === "filterBranch") {
      const str = value !== "" && value.split(",");
      const option =
        str.length >= 1
          ? `Branch (+ ${str.length}) `
          : str.length === 0
            ? handleDelete(key)
            : filterObj.filterBranch;
      return option;
    } else {
      const option =
        options &&
        options.length > 0 &&
        options.find((item) => item.id === value);
      return option ? option.title || option.label : "";
    }
  };

  const displaySnackBarMessage = (severity, errorMessage) => {
    setNotification({
      severity: severity,
      message: errorMessage,
    });
    setOpenSnakBar(true);
  };

  async function statusChangeOnDownload2 (cases, token,countDownload, casesCount) {

    let result = await trackTPRDownload(
      cases.sfId,
      cases.external_id
    );

    if (true == true) {
      await setRHSStatus(
        cases.requestId,
        "TreatmentPlanDelivered",
        token
      )
        .then(async response => {

          if (false) {

            let obj2 = {
              check: true,
              cases: cases.caseId
            }
            localStorage.setItem("selectDownload", JSON.stringify(obj2));
            const c = window.location.href;
            let checkCaseCardPage = c.includes("CaseCardView");
          }

        })

        .catch((response) => {
        });
    }
  }

  async function statusChangeOnDownload (cases, token,countDownload, casesCount) {
    let result = await trackTPRDownload(
      cases.sfId,
      cases.external_id
    );

    if (result.data.trackTPRDownload == true) {
      await setRHSStatus(
        cases.requestId,
        "TreatmentPlanDelivered",
        token
      )
        .then(async response => {
          if (response.data.commentedRequestState.requestState == 'TreatmentPlanDelivered') {
            let obj2 = {
              check: true,
              cases: cases.caseId
            }
            localStorage.setItem("selectDownload", JSON.stringify(obj2));
            const c = window.location.href;
            let checkCaseCardPage = c.includes("CaseCardView");
            if (countDownload == casesCount && checkCaseCardPage == true) {
              window.localStorage.setItem("isThisInLocalStorage", "true");
              window.dispatchEvent(new Event("storage"));
            }
          }
          setDownloadComplete(true);
        })
        .catch((response) => {
        });
    }
  }

  async function downloadReport() {
    setLoader(true);
    const cases = JSON.parse(localStorage.getItem("selectedCheckBoxes"));
    var l = JSON.parse(localStorage.getItem(ASYNC_STORAGE.TOKEN));
    let t = true;
    let countDownload = 0;
    for (let i = 0; i < cases.length; i++) {
      const associatedTreatmentPlanRequestIds = cases[i].tprs;
      const configFile = {
        method: "GET",
        url: `${API.RHS.TREATMENT_PLAN_URI}/metadata?associatedTreatmentPlanRequestId=${associatedTreatmentPlanRequestIds}&latestUploadedOnly=${t}`,
        data: {},
        headers: {
          Authorization: "Bearer " + l.mako_token,
          "Content-Type": "application/json",
        },
      };
      let responseDownload = await axios(configFile)
        .then((response) => {
          let downloadedFileName = response.data[0].treatmentPlanFilename;
          const treatmentId = response.data[0].treatmentPlanId;
          const config = {
            method: "GET",
            url: `${API.RHS.TREATMENT_PLAN_URI}/${treatmentId}`,
            data: {},
            headers: {
              Authorization: "Bearer " + l.mako_token,
              "Content-Type": "application/octet-stream",
            },
            responseType: "blob",
          };
          let resp = axios(config)
            .then((response) => {
              let filename = downloadedFileName;
              let r =   fileDownload(response.data, filename);
              countDownload++;
              statusChangeOnDownload (cases[i], l.mako_token, countDownload, cases.length);
              setLoader(false);
            

            })
            .catch((error) => {
              setLoader(false);
              console.log(error);
            });
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  }
  useEffect(()=> {
    let check = localStorage.getItem("isThisInLocalStorage");
    if (check === "true") {
      setTimeout(()=>{
        localStorage.setItem("isThisInLocalStorage", "false");
        props.getCasesByFilter(filterObj);
      },15000);
    }
  },[downloadComplete]);

  let [caseStatus, setData] = useState(true);
  let casesLength = 0;
  useEffect(() => {
    window.addEventListener("change", function () {
      casesLength = JSON.parse(localStorage.getItem("selectedCheckBoxes"));
      if (casesLength == null) {
        return;
      }
      if (casesLength.length > 0) {
        setData(false);
      } else {
        setData(true);
      }
    });

    window.addEventListener("storage2", function () {
      casesLength = JSON.parse(localStorage.getItem("selectedCheckBoxes"));
      if (casesLength == null) {
        setData(true);
        return;
      }
      if (casesLength.length > 0) {
        setData(false);
      } else {
        setData(true);
      }
    })

  }, ["value"]);

  return (
    <>
      <SimpleBackdrop open={loader} />
      <Snackbars
        severity={notification.severity}
        open={openSnakBar}
        handleClose={() => setOpenSnakBar(false)}
        message={notification.message}
      />
      <Grid container xs={12} style={{ marginLeft: "15px" }}>
        <Grid item xs={8} align="left" style={{ marginTop: "10px" }}>
          {Object.keys(filterObj).map((key) => {
            return chipAction(false, key);
          })}
        </Grid>
        <Grid item xs={4} align="right">
          <CardContent>
            <Typography>
              {props.open ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    flexWrap: "wrap",
                    float: "right",
                    marginBottom: "1px",
                  }}
                >
                  <div>
                    <Button
                      size="medium"
                      variant="contained"
                      className={classes.createButton1}
                      disabled={caseStatus}
                      onClick={() => downloadReport()}
                    >
                      DOWNLOAD SELECTED TREATMENT PLANS
                    </Button>
                  </div>
                  <div>
                    <span class="filter-text">Filter</span>{" "}
                    <CloseIcon
                      onClick={() => props.setOpen(false)}
                      fontSize="small"
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "right",
                    flexWrap: "wrap",
                    float: "right",
                    marginBottom: "1px",
                  }}
                >
                  <div>
                    <Button
                      size="medium"
                      variant="contained"
                      className={classes.createButton1}
                      disabled={caseStatus}
                      onClick={() => downloadReport()}
                    >
                      DOWNLOAD SELECTED TREATMENT PLANS
                    </Button>
                  </div>
                  <div className={classes.filterText}>
                    <span
                      className={classes.filterText}
                      style={{
                        margintop: "15px",
                      }}
                    >
                      Filter
                    </span>
                  </div>
                  <div className={classes.filterIcon}>
                    <FilterListIcon
                      onClick={() => props.setOpen(true)}
                      fontSize="small"
                    />
                  </div>
                </div>
              )}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
      {props.open && (
        <Grid container justify="center" style={{ padding: "0px 25px" }}>
          <Grid container justify="space-evenly">
            <Grid item xs={3}>
              <Dropdown
                label={"Division"}
                ctrllabel={"All"}
                fnc={(e) => handleDivisionDropdown(e)}
                options={divisionOptions}
                value={props.division}
              />
            </Grid>
            <Grid item xs={3}>
              <CheckBoxMenu
                label={"Branch"}
                onChange={(e) => handleBranchDropdown(e)}
                options={branchOptions}
                value={props.branch}
              />
            </Grid>
            <Grid item xs={3}>
              <CheckBoxMenu
                // fnc={(event)=>handleSalesRepDropdown(event)}
                onChange={(event) => handleSalesRepDropdown(event)}
                label={"Sales Rep"}
                value={props.salesRep}
                options={salesRepOptions}
              />
            </Grid>

            <Grid item xs={3}>
              <CheckBoxMenu
                label={"Sales Team"}
                onChange={handleSalesTeamDropdown}
                options={salesTeamOptions}
                value={props.salesTeam}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-evenly">
            <Grid item xs={3}>
              <CheckBoxMenu
                isAstrikReq={true}
                onChange={handleHospitalChange}
                label={"Hospital"}
                value={props.hospital}
                options={hospitalOptions}
              />
            </Grid>
            <Grid item xs={3}>
              <CheckBoxMenu
                onChange={handleSurgeonChange}
                label={"Surgeon"}
                value={props.surgeon}
                options={surgeonOptions}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickers
                label="Date Range"
                isAstrikReq={false}
                fnc={onChangeFromDate}
                value={props.startDate}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickers
                label=""
                isAstrikReq={false}
                fnc={onChangeToDate}
                value={props.endDate}
                minDate={moment(props.startDate).add(1, "days")}
                isdisabled={!props.startDate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="flex-start"
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <MultiSelectStatus
                label={"Status"}
                fnc={(e, options) => handleStatusSelection(e, options)}
                options={CALENDAR_STATUS}
                value={props.status}
              />
            </Grid>
            <Grid item xs={3}>
              <Dropdown
                label={"Role"}
                ctrllabel={"Please Select"}
                fnc={handleRoleSelect}
                options={roleOptions}
                value={props.role}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                <FormGroup aria-label="Temas" row={true}>
                  {caseTypes.map((item, i) => (
                    <FormControlLabel
                      className={classes.checkboxall}
                      key={i}
                      control={
                        <StyledCheckbox
                          key={i}
                          color="default"
                          checked={
                            item === "MAKO"
                              ? props.mako
                              : item === "AS-1"
                                ? props.as1
                                : item === "Error"
                                  ? props.webOpsError
                                  : props.confirmed
                          }
                          onChange={handleCheckBoxClick}
                          name={item}
                          value="left"
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      }
                      label={item}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid
              item
              xs={3}
              justify="flex-start"
              style={{ paddingLeft: "10px" }}
            >
              <div style={{ fontWeight: 700, fontSize: "12px" }}>
                {"Preset Filters"}
              </div>
              <Button
                className={classes.presetFilterButton}
                fullWidth
                aria-controls="menu"
                aria-haspopup="true"
                size="medium"
                endIcon={<ArrowDropDown />}
                onClick={(e) => setAnchor(e.currentTarget)}
              >
                <div
                  className={classes.presetFilterText}
                  style={{ color: props.selectedFilterName ? "#000" : "#c5c5c5" }}
                >
                  {`${props.selectedFilterName
                    ? props.selectedFilterName
                    : props.checkFilterName
                      ? props.checkFilterName
                      : "Please Select"
                    }`}
                </div>
              </Button>
            </Grid>
            <Grid item xs={9} justify="flex-end">
              <CardContent>
                <Typography>
                  <div className="rFloat grey-color btnSaveCancel">
                    <Buttons Icon="RESET" name="RESET" fnc={handleresetClick} />
                  </div>
                  <div className="rFloat btnSaveCancel">
                    <Buttons
                      Icon="Submit"
                      toSubmit={true}
                      fnc={handleFilterClick}
                      name="Filter"
                    />
                  </div>
                  <div className="rFloat btnSaveCancel">
                    <Buttons
                      Icon="SAVE"
                      name="SAVE"
                      toSave={true}
                      fnc={() => setSaveFilter(true)}
                    />
                  </div>
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Grid>
      )}
      <PresetFilterMenu
        anchor={anchor}
        items={presetFilters}
        selectedFilter={props.selectedFilterName}
        checkFilterName={props.checkFilterName}
        handleClose={() => setAnchor(null)}
        handleChange={handlePresetFilterChange}
        handleDelete={handleDeleteFilter}
        handleCheckBox={handleCheckBox}
      />
      <SaveFilterDialog
        open={saveFilter}
        selectedFilter={props.selectedFilterName}
        handleClose={() => setSaveFilter(false)}
        handleSave={handleSaveFilter}
        handleUpdate={handleUpdateFilter}
      />
    </>
  );
};

export default Filter;
